import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faFacebookMessenger, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import { ReactComponent as LogoInitials } from "./../../assets/images/logo-initials.svg";

const Footer = () => {

  const year = new Date().getFullYear();

  return (
    <>

      <footer className="footer">

        <div className="footer__logo">
          <LogoInitials />
        </div>

        <div className="footer__content">
          <div className="row">

            <div className="column column-lg-2">
              <div className="footer-contact">
                <h3 className="footer-contact__title">Contact us now!</h3>
                <p className="bigger">
                  <a href="mailto:mezei@silberstein.hu" target="_blank" rel="noreferrer noopener" title="Write us!">mezei@silberstein.hu</a>
                </p>
              </div>

              <ul className="footer-social-icons">
                <li className="footer-social-icons__item">
                  <a href="https://fb.me/silbersteinandpartners" target="_blank" rel="noreferrer noopener" title="Visit our Facebook page" className="footer-social-icons__link">
                    <FontAwesomeIcon icon={faFacebook} />
                    <span className="sr-only">Visit our Facebook page</span>
                  </a>
                </li>
                <li className="footer-social-icons__item">
                  <a href="https://m.me/silbersteinandpartners" target="_blank" rel="noreferrer noopener" title="Write us on Facebook Messenger" className="footer-social-icons__link">
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                    <span className="sr-only">Write us on Facebook Messenger</span>
                  </a>
                </li>
                <li className="footer-social-icons__item">
                  <a href="https://www.linkedin.com/company/silberstein-and-partners/" target="_blank" rel="noreferrer noopener" title="Check out our LinkedIn page" className="footer-social-icons__link">
                    <FontAwesomeIcon icon={faLinkedin} />
                    <span className="sr-only">Check out our LinkedIn page</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="column column-sm-1 column-md-1">
              <nav className="footer-navigation" role="navigation" aria-label="footer-main-navigation">
                <NavLink to="/" className="footer-navigation__link">
                  Home
                </NavLink>
                <NavLink to="/about" className="footer-navigation__link">
                  About
                </NavLink>
                <NavLink to="/services" className="footer-navigation__link">
                  Services
                </NavLink>
                <NavLink to="/clients" className="footer-navigation__link">
                  Clients
                </NavLink>
                <NavLink to="/contact" className="footer-navigation__link">
                  Contact
                </NavLink>
              </nav>
            </div>

            {/* 
            <div className="column column-sm-1 column-md-1">
              <nav className="footer-navigation" role="navigation" aria-label="footer-secondary-navigation">
                <NavLink to="/privacy-policy" className="footer-navigation__link">
                  Privacy Policy
                </NavLink>
                <NavLink to="/terms-and-conditions" className="footer-navigation__link">
                  Terms & Conditions
                </NavLink>
              </nav>
            </div>
            */}

          </div>
        </div>

        <div className="footer__bar">
          <div className="row">
            <div className="column column-lg-4">
              <p className="smaller spaced">
                &copy; 2006-{year} Silberstein & Partners.
              </p>
            </div>
          </div>
        </div>
      </footer>
    
    </>
  );
}

export default Footer;
