import React from "react";

import { Fade } from "react-awesome-reveal";

import useDocumentTitle from '../components/setDocumentTitle';

const PrivacyPolicy = () => {
  
  useDocumentTitle("Privacy Policy");

  return (
    <>
      
      <div className="page-title">
        <div className="row">
          <div className="column column-full">

            <Fade direction="down" delay={1000}>
              <div className="page-title__content spaced">
                <span>Privacy Policy</span>
              </div>
            </Fade>

          </div>
        </div>
      </div>

      <section className="section section--simple-document">
        
        <div className="row">
          <div className="column column-lg-3">

            <h1>Privacy Policy</h1>
            <p className="bigger spaced">Last updated: 2023.07.26.</p>

            <p>__</p>

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, illo! Quos accusantium asperiores itaque iure atque consectetur, ipsam, placeat dolorem obcaecati ut rem facilis officiis hic dignissimos ullam delectus quae!</p>
            <p>Sed adipisci velit sunt fuga facere id, iure ratione tempore quia dignissimos cum similique cupiditate voluptatibus libero tenetur sapiente illum placeat. Sit dolore quos blanditiis eaque nisi, ipsum aliquam ullam!</p>

            <h2>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis repudiandae, magni sed obcaecati quam repellendus assumenda maiores labore facilis rerum aut? In delectus quidem fuga quibusdam perferendis praesentium hic maiores.</h2>
            <p>Voluptatum atque ipsa unde rem exercitationem dolorum quasi labore commodi, repudiandae expedita inventore dolores cumque laborum voluptatibus quaerat necessitatibus in vel ullam consectetur mollitia earum? Quidem optio facere veritatis assumenda!</p>

            <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, maxime ipsam. Est molestiae beatae pariatur dolore, quia iure ullam perferendis voluptatum dolores, optio, repellat iusto excepturi? Explicabo dolores mollitia deleniti!</h3>
            <p>Pariatur porro animi debitis cum minima architecto harum error quos a adipisci non accusantium, dolorem fugit impedit quia est repellendus culpa nisi neque, eaque consectetur nostrum tempora? Impedit, molestiae labore.</p>
            <p>Labore, eius tenetur. Soluta, assumenda et debitis temporibus, cupiditate laudantium ratione dolores modi eius enim, vitae harum quia? Nam similique ducimus facilis culpa iusto corporis aperiam assumenda possimus, accusantium atque.</p>
            <p>Tempora a laudantium quibusdam vitae adipisci quia, ipsam incidunt, neque, sint sit ipsum eos optio laborum officia ea inventore labore. Minima cupiditate aut nulla eius, voluptatem molestias illo est rem!</p>
            <p>Inventore atque earum perspiciatis autem tempora, quaerat porro tempore, aliquid amet, consectetur dolor provident eaque excepturi pariatur cumque at! Repellendus esse neque magni nobis quisquam nam dignissimos quas velit aspernatur!</p>
            <p>Officiis minus iste quae eaque odit dolores quod sunt architecto nulla ad, sapiente, laborum, velit ut. Vel reprehenderit aperiam quis saepe earum vero, consequatur quam eaque ea, quasi, quos nam.</p>
            <p>Ab quia distinctio odit voluptates necessitatibus consectetur ad explicabo error maxime, quas fugiat eum dolore illo eveniet laboriosam quaerat adipisci vitae ex nemo veniam. Dolor et accusantium explicabo corporis id.</p>
            <p>Ullam illum repellendus repudiandae beatae hic eligendi ratione nulla! Architecto, mollitia quod rerum quis odit voluptatem quos molestiae deserunt ipsam nam hic sapiente ut explicabo laudantium eius vero, possimus doloribus.</p>
            <p>Deleniti assumenda suscipit cum iure vitae, aspernatur in dicta consequatur molestiae nihil laudantium accusantium. Iure et, laudantium accusantium modi voluptates exercitationem neque ullam necessitatibus quibusdam, cumque eos quidem sapiente autem.</p>
            <p>Deleniti nostrum consectetur accusantium quidem sapiente recusandae cupiditate reiciendis, non totam magnam dolores ex nesciunt beatae repellat doloribus cum vitae rem. Eligendi eaque id at nostrum iste voluptatibus alias dolorem.</p>
            <p>Quisquam, ipsa optio! Totam asperiores magnam aliquid ipsa optio officiis voluptas odio ipsum voluptatibus ipsam a velit necessitatibus nostrum earum quaerat, consequatur at. Veniam sed, cupiditate nostrum excepturi corporis ipsa.</p>
            <p>Accusantium libero cum voluptas, ex molestiae ab quasi quisquam facilis nihil amet dignissimos praesentium harum et eaque quis illum? Iste sit eum hic expedita! Numquam odit rem aut placeat eius.</p>
            <p>Qui, possimus fugiat mollitia blanditiis culpa dolorem ex, sunt placeat minus enim hic? Ut qui ipsum quasi nemo pariatur modi minima alias, impedit neque magnam ex esse labore doloribus itaque!</p>
            <p>Laudantium possimus eaque assumenda ipsam distinctio. Eligendi magnam tenetur unde consequatur quia? Repellendus, aperiam doloremque consectetur aliquam, reprehenderit nostrum repellat placeat neque quae nemo ab explicabo ipsa labore voluptas dolorum.</p>
            <p>Culpa, placeat corporis soluta voluptate laudantium enim tenetur deserunt recusandae voluptatem omnis ratione ab voluptates alias id totam, quidem laboriosam earum eligendi. Eligendi quidem, quia sit repellendus mollitia minima nam?</p>
            <p>Sapiente possimus neque optio! Sed accusamus saepe hic quisquam? Nobis, accusamus perferendis! Nihil exercitationem mollitia voluptatum quam quo pariatur expedita laudantium illo illum facilis, hic qui doloribus? Distinctio, voluptatibus delectus.</p>
            <p>Sequi eaque ex ducimus repellendus dolorum, maiores rem optio quo reprehenderit, perferendis laborum earum nostrum mollitia hic sit exercitationem omnis inventore ipsa adipisci ab vel voluptatibus. Beatae expedita deserunt sapiente.</p>
            <p>Blanditiis nam possimus necessitatibus excepturi, error soluta unde doloremque accusamus magni dolore, porro recusandae mollitia nemo aperiam eos eaque. At, tenetur! Inventore illo neque fugit autem deleniti saepe blanditiis ducimus!</p>
            <p>Mollitia recusandae, quibusdam corporis ea vel, inventore aliquid rerum facere quos cupiditate nostrum dolorum sit, minus cum tenetur tempora ratione error dolores. Sint corporis velit repellendus molestias reiciendis explicabo deserunt.</p>
            <p>Quas doloribus iure deserunt quaerat maiores aut qui aperiam quod, vero debitis nobis perferendis perspiciatis recusandae nulla omnis ut harum officiis error! Nobis, dolore tempore unde necessitatibus cum in suscipit!</p>
            <p>Eveniet quae aperiam inventore iusto earum perspiciatis commodi illum facilis amet, non tenetur ipsa voluptas praesentium accusamus doloribus quod dignissimos iure est quia. A iusto magni reiciendis, aliquid ipsum autem.</p>
            <p>Debitis, aut est provident, molestias ullam quas ad consequatur tempora nostrum consequuntur illum rem necessitatibus et! Eligendi itaque amet eius? Repudiandae facilis animi, excepturi autem similique quis in assumenda est.</p>
            <p>Laudantium exercitationem neque eaque nisi, nihil rem sed animi quae doloribus dolor, alias quam debitis repellat sunt iste vitae necessitatibus! Aut ad perferendis mollitia quisquam ut placeat ab maiores deleniti?</p>
            <p>Natus earum possimus reiciendis, ad culpa, molestiae sit inventore, eos aperiam blanditiis architecto. Nihil consectetur sit, officia cupiditate harum molestiae architecto accusantium, optio quisquam laboriosam explicabo, expedita voluptates fugit ratione.</p>
            <p>Illum saepe quis quod, architecto maiores, beatae repellat voluptate culpa pariatur ad corrupti debitis nostrum veritatis, recusandae labore iusto ducimus itaque facere esse asperiores eum aliquam exercitationem ullam! Corporis, libero.</p>
            <p>Ipsam error hic, numquam accusamus, est vitae aliquam voluptas quam nulla sequi voluptatem ducimus! Amet porro similique fugit saepe obcaecati iste aliquid quibusdam laboriosam autem fugiat totam, vitae repellat sapiente?</p>
            <p>Veritatis fugit nemo iure possimus deleniti ipsam nesciunt quae atque sint, minima sapiente hic quisquam modi voluptatibus iusto? Facilis quis maiores ea quam cupiditate commodi, eum molestias expedita eius odio?</p>
            <p>Praesentium saepe omnis optio illo autem distinctio, tenetur culpa perferendis? Ut ipsam ratione excepturi voluptatibus vel sint delectus est illo accusantium odio beatae eligendi, iure aspernatur vitae quisquam! Ut, minus.</p>

          </div>
        </div>

      </section>

    </>
  );
}

export default PrivacyPolicy;
