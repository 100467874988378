import React from "react";

import { Fade } from "react-awesome-reveal";
import { NavLink } from "react-router-dom";

import useDocumentTitle from "../components/setDocumentTitle";

const NotFound = () => {

  useDocumentTitle("Not found");

  return (
    <>

      <section className="section section--not-found">

        <span className="huge404">404</span>

        <div className="row">
          <div className="column column-lg-3">

            <Fade triggerOnce>
              <h1>Dang it! This page doesn&rsquo;t seem to exist.</h1>
            </Fade>

            <Fade delay={500} triggerOnce>
              <p className="bigger" style={{marginTop: "1rem"}}>
                It looks like the link pointing here was faulty. Try to get back to <NavLink to="/" title="Home">Home</NavLink>.
              </p>
            </Fade>

          </div>
        </div>

      </section>

    </>
  );
}

export default NotFound;
