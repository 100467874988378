import React from "react";

const Image = ({
    jpeg = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    webp = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    className,
    title,
    alt = title,
    loading = "lazy",
    draggable = "false",
    caption,

    ...delegated
  }) => {

  return (

    <>
    
      <figure className={`figure ${className} ${caption ? "figure--with-caption" : ""}`}>
        
          <picture className="figure__picture">
            <source srcSet={webp} type="image/webp" />
            <source srcSet={jpeg} type="image/jpeg" />
            <img className="figure__src" src={jpeg} title={title} alt={alt} loading={loading} draggable={draggable} {...delegated} />
          </picture>

          {caption ? <figcaption className="figure__caption">{caption}</figcaption> : ""}
      </figure>

    </>
  );
}

export default Image;
