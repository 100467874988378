import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { Cross as Hamburger } from "hamburger-react"
import { ArrowDownRight } from "lucide-react";

import Image from "../image";

import HeaderImage from "./../../assets/images/header-cover-01.jpg";
import HeaderImageWebp from "./../../assets/images/header-cover-01.webp";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>

      <header className="site-header" aria-expanded={isOpen}>
        
        <div className="row">
          <div className="column column-lg-2">

            <nav className="main-navigation" role="navigation" aria-label="main-navigation">

              <Fade cascade direction={"left"} damping={0.15} duration={750} triggerOnce>
              
                <NavLink to="/about" className="main-navigation__link" title="About" onClick={() => setIsOpen(false)}>
                  <span className="main-navigation__number smaller">01</span>
                  <span className="main-navigation__title lead">
                    <span className="main-navigation__title-text">
                      About
                    </span>
                    <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                  </span>
                </NavLink>

                <NavLink to="/services" className="main-navigation__link" title="Services" onClick={() => setIsOpen(false)}>
                  <span className="main-navigation__number smaller">02</span>
                  <span className="main-navigation__title lead">
                    <span className="main-navigation__title-text">
                      Services
                    </span>
                    <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                  </span>
                </NavLink>

                <NavLink to="/clients" className="main-navigation__link" title="Clients" onClick={() => setIsOpen(false)}>
                  <span className="main-navigation__number smaller">03</span>
                  <span className="main-navigation__title lead">
                    <span className="main-navigation__title-text">
                      Clients
                    </span>
                    <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                  </span>
                </NavLink>

                <NavLink to="/contact" className="main-navigation__link" title="Contact" onClick={() => setIsOpen(false)}>
                  <span className="main-navigation__number smaller">04</span>
                  <span className="main-navigation__title lead">
                    <span className="main-navigation__title-text">
                      Contact
                    </span>
                    <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                  </span>
                </NavLink>

              </Fade>
            </nav>

            <div className="site-header__contact">
              <ul className="site-header__contact-list">

                <li className="site-header__contact-item">
                  <span className="site-header__contact-type">
                    phone
                  </span>
                  <a className="site-header__contact-data" href="tel:+36205000365" title="Call us!" target="_blank" rel="noreferrer noopener">
                    +36 20 5000 365
                  </a>
                </li>
                <li className="site-header__contact-item">
                  <span className="site-header__contact-type">
                    email
                  </span>
                  <a className="site-header__contact-data" href="mailto:mezei@silberstein.hu" title="Write us!" target="_blank" rel="noreferrer noopener">
                    mezei@silberstein.hu
                  </a>
                </li>

              </ul>
            </div>

          </div>

          <div className="column column-lg-2">
            <div className="site-header__image">
              {/* <Image title="Green leaves" jpeg={HeaderImage} webp={HeaderImageWebp} className="figure--site-header-cover" /> */}
              <Image title="A close up of a colorful liquid" jpeg={HeaderImage} webp={HeaderImageWebp} className="figure--site-header-cover" />
            </div>
          </div>
        </div>

        <div className="site-header__toggle">
          <span className="site-header__toggle-text spaced">
            {isOpen ? "close" : "menu"}
          </span>
          <Hamburger size={24} label="Expand navigation" toggled={isOpen} toggle={setIsOpen} />
        </div>

      </header>

    </>
  );
}

export default Header;
