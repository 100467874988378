import React from "react";

import useDocumentTitle from "../components/setDocumentTitle";
import useDocumentColors from "../components/setDocumentColors";
import AccordionItem from "./../components/accordionItem";
import Image from "./../components/image";

import { Fade } from "react-awesome-reveal";
import { Accordion } from "@szhsin/react-accordion";
import { ReactTyped } from "react-typed";

import HeroImage from "./../assets/images/about-hero.jpg";
import HeroImageWebp from "./../assets/images/about-hero.webp";
import Img01 from "./../assets/images/about-01-company.jpg";
import Img01Webp from "./../assets/images/about-01-company.webp";
import Img02 from "./../assets/images/about-02-company.jpg";
import Img02Webp from "./../assets/images/about-02-company.webp";
import Img06 from "./../assets/images/about-06-culture.jpg";
import Img06Webp from "./../assets/images/about-06-culture.webp";
import Img07 from "./../assets/images/about-07-best-features.jpg";
import Img07Webp from "./../assets/images/about-07-best-features.webp";

import AdamVass from "./../assets/images/colleagues/adam-vass.jpg";
import AdamVassWebp from "./../assets/images/colleagues/adam-vass.webp";
import AnnelCorrea from "./../assets/images/colleagues/annel-correa.jpg";
import AnnelCorreaWebp from "./../assets/images/colleagues/annel-correa.webp";
import DanielJohnson from "./../assets/images/colleagues/daniel-johnson.jpg";
import DanielJohnsonWebp from "./../assets/images/colleagues/daniel-johnson.webp";
import GergoSimko from "./../assets/images/colleagues/gergo-simko.jpg";
import GergoSimkoWebp from "./../assets/images/colleagues/gergo-simko.webp";
import IstvanGooncziKovacs from "./../assets/images/colleagues/istvan-goonczi-kovacs.jpg";
import IstvanGooncziKovacsWebp from "./../assets/images/colleagues/istvan-goonczi-kovacs.webp";
import MarkMezei from "./../assets/images/colleagues/mark-mezei.jpg";
import MarkMezeiWebp from "./../assets/images/colleagues/mark-mezei.webp";
import PeterErdos from "./../assets/images/colleagues/peter-erdos.jpg";
import PeterErdosWebp from "./../assets/images/colleagues/peter-erdos.webp";

const About = () => {

  useDocumentTitle("About");
  useDocumentColors("var(--color-dreamweaver)");

  return (
		<>
			<div className="page-title">
				<div className="row">
					<div className="column column-full">
						<Fade direction="down" delay={1000} triggerOnce>
							<div className="page-title__content spaced">
								<span>01</span>
								<span>About</span>
							</div>
						</Fade>
					</div>
				</div>
			</div>

			<section className="section section--hero">
				<div className="row">
					<div className="column column-lg-4 column-xl-3">
						<h1>
							<ReactTyped
								strings={["We do, not try."]}
								typeSpeed={40}
								startDelay={500}
							/>
						</h1>
					</div>
				</div>
				<div className="row">
					<div className="column column-lg-2">
						<Fade direction="up" delay={1000} triggerOnce>
							<p className="bigger">
								We are a full-service digital agency with extensive expertise
								and experience, providing seamless, efficient, and well-managed
								digital support.
							</p>
						</Fade>
					</div>
				</div>
			</section>

			<section className="section section--hero-image">
				<Image
					jpeg={HeroImage}
					webp={HeroImageWebp}
					className="figure--about-hero"
					title="Businessman dressed in white shirt sitting in cafe and using laptop"
					alt="Businessman dressed in white shirt sitting in cafe and using laptop"
				/>
			</section>

			<Accordion
				transition
				transitionTimeout={250}
				onStateChange={({ key: item, current: { status } }) => {
					if (status === "entered") {
						item.scrollIntoView({ behavior: "smooth" });
					}
				}}
			>
				<section className="section section--about-company">
					<AccordionItem header="Company">
						<div className="row">
							<div
								className="column column-lg-2"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
							>
								<Fade direction="up" triggerOnce>
									<p className="lead">We&rsquo;re around since 2006.</p>
								</Fade>

								<Image
									className="figure--about-01"
									jpeg={Img01}
									webp={Img01Webp}
									title="Web designer creating layout for new startup website"
								/>
							</div>
							<div
								className="column column-lg-2"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
							>
								<Fade cascade triggerOnce>
									<p>
										Silberstein and Partners will be 17 years old in August
										2023, making us one of Hungary&rsquo;s oldest independent
										digital + PR agencies.
									</p>
									<p>
										We are a small group of creative and imaginative individuals
										who not only tackle problems and develop ideas for
										companies, but also specialize in digital planning,
										pay-per-click, social media marketing, and public relations.
									</p>
								</Fade>

								<Image
									className="figure--about-02"
									jpeg={Img02}
									webp={Img02Webp}
									title="Programmer working in a software develop company office"
								/>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-3">
								<Fade cascade triggerOnce>
									<p>
										Both of our founder/manager, Mark Mezei, and the head of
										digital, István Gönczi-Kovács, began their careers in the
										communication industry. Mezei led the most prestigious PR
										and communication firms (Sawyer Miller Group, Ogilvy Pr),
										whilst Gönczi-Kovács was led one of the biggest digital
										agancies.
									</p>
									<p>
										Our team is aware the fact that all our business partners
										have put a lot of effort and financial investment into
										getting where they are today. Therefore, we put
										collaboration at the very heart of our projects — bringing
										together the best experts in strategy, design, and
										technology — to create digital experiences that transform
										how we connect and engage. In nutshell, our clients can
										maximize their capabilities, thrive in the competitive and
										dynamic digital environment with us.
									</p>
								</Fade>
							</div>
						</div>
					</AccordionItem>
				</section>

				<section className="section section--about-colleagues">
					<AccordionItem header="Colleagues">
						<div className="row">
							<div className="column column-lg-3">
								<Fade direction="up" triggerOnce>
									<p className="lead" style={{ marginBottom: "3rem" }}>
										We are a group of individuals who are united by our
										connections, strengthened by our pursuit of mastery, and
										propelled towards the future with agility.
									</p>
								</Fade>
							</div>
						</div>

						<div className="row row--three-col">
							<Fade cascade damping={0.5} className="column" triggerOnce>
								<Image
									jpeg={MarkMezei}
									webp={MarkMezeiWebp}
									className="figure--colleague"
									title="Márk Mezei (Managing partner)"
									caption="Márk Mezei (Managing partner)"
								/>
								<Image
									jpeg={IstvanGooncziKovacs}
									webp={IstvanGooncziKovacsWebp}
									className="figure--colleague"
									title="István Gönczi-Kovács (Online marketing, ppc)"
									caption="István Gönczi-Kovács (Online marketing, ppc)"
								/>
								<Image
									jpeg={AdamVass}
									webp={AdamVassWebp}
									className="figure--colleague"
									title="Ádám Vass (Online marketing, ppc)"
									caption="Ádám Vass (Online marketing, ppc)"
								/>
								<Image
									jpeg={GergoSimko}
									webp={GergoSimkoWebp}
									className="figure--colleague"
									title="Gergő Simkó (Head of Development)"
									caption="Gergő Simkó (Head of Development)"
								/>
								<Image
									jpeg={PeterErdos}
									webp={PeterErdosWebp}
									className="figure--colleague"
									title="Péter Erdős (Deadline Agency, Design)"
									caption="Péter Erdős (Deadline Agency, Design)"
								/>
								<Image
									jpeg={DanielJohnson}
									webp={DanielJohnsonWebp}
									className="figure--colleague"
									title="Daniel Johnson (Sales representative, US)"
									caption="Daniel Johnson (Sales representative, US)"
								/>
								<Image
									jpeg={AnnelCorrea}
									webp={AnnelCorreaWebp}
									className="figure--colleague"
									title="Annel Correa (Sales representative, Canada)"
									caption="Annel Correa (Sales representative, Canada)"
								/>
							</Fade>
						</div>
					</AccordionItem>
				</section>

				<section className="section section--about-culture">
					<AccordionItem header="Culture">
						<div className="row" style={{ alignItems: "center" }}>
							<div className="column column-lg-2">
								<Fade direction="up" triggerOnce>
									<p className="bigger">
										Culture is one of those nice buzzwords that every company
										today uses. To us, culture is more than just a term;
										it&rsquo;s a foundation, a warm joyful feeling, and the glue
										that holds our wonderfully imperfect team together. We
										learned early on that without a strong culture, you are just
										a group of individuals going about your day jobs, headed in
										various ways, with nothing linking you in a shared office
										space. When you have a culture like ours, you can establish
										a team of like-minded, ambitious, passionate, and fearless
										individuals who are willing to go to any length to follow
										the firm&rsquo;s mission and devote their entire self for
										the company.
									</p>
								</Fade>
							</div>
							<div className="column column-lg-2">
								<Image
									jpeg={Img06}
									webp={Img06Webp}
									className="figure--about-06"
									title="A woman sitting at a desk with a laptop in front of her"
								/>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Fade direction="up" triggerOnce>
									<h3>Our values</h3>
								</Fade>

								<Fade cascade damping={0.5} direction={"up"} triggerOnce>
									<ol className="styled-list styled-list--numbered styled-list--two-col">
										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Honesty and transparency
											</h4>
											<p>
												Our number one goal is to help our clients succeed. We
												believe our team and our clients can be successful only
												with clear and quantifiable success metrics that allow
												us to align with our mission.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Cooperation</h4>
											<p>
												We know that great work comes from great teamwork, so we
												value the culture of open communication and mutual
												support.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Individuality</h4>
											<p>
												Variety of abilities, talents, and aspirations power our
												workforce. We understand that diversity is essential to
												our group chemistry, therefore we respect the unique
												traits that make us distinctive.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Connection</h4>
											<p>
												We value authentic and trustworthy relationships in
												partnership – we define success by the depth of human
												connections; they are what give us meaning and relate us
												to our work in the collective pursuit of building
												something great together.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Lifelong learning</h4>
											<p>
												Marketing continues to evolve or change, and so do we.
												We are lifelong learners; we are always eager to learn
												more and embrace new ideas and technologies with
												enthusiasm.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Agility</h4>
											<p>
												Change is the only constant in our world, and change
												means opportunity for us. We chase this opportunity with
												energy and optimism by embracing the unknown and
												inventing a better tomorrow ripe for reinvention.
											</p>
										</li>
									</ol>
								</Fade>
							</div>
						</div>
					</AccordionItem>
				</section>

				<section className="section section--about-best-features">
					<AccordionItem header="Features our clients love">
						<div className="row">
							<div className="column column-lg-3">
								<Fade direction="up" triggerOnce>
									<p className="lead">
										Good marketing makes the company look smart. Great marketing
										makes the customer feel smart (cf: the best marketing
										doesn't feel like marketing).
									</p>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Image
									jpeg={Img07}
									webp={Img07Webp}
									className="figure--about-07"
									title="A man with a beard working on a computer."
								/>

								<Fade cascade damping={0.5} direction={"up"} triggerOnce>
									<ol className="styled-list styled-list--numbered styled-list--two-col">
										<li className="styled-list__item">
											<h4 className="styled-list__title">24/7 agency access</h4>
											<p>
												We&rsquo;re always here for you, you can always rely on
												us day and night. If you have any questions or issues,
												we can be reached by phone or email 24/7.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">People-powered</h4>
											<p>
												We believe in AI-first approach so we can focus on the
												aspects of engagement that make us more human:
												problem-solving, creativity, empathy, and partner
												relationships.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Regular, monthly meetings
											</h4>
											<p>
												You can meet with our marketing team each month to
												discuss your progress, evaluate and measure the achieved
												success, and look at new opportunities.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Excellent reporting
											</h4>
											<p>
												People who say reports are boring are mistaken. Reports
												are a lot of fun when you can turn them into a story:
												what happened in the last month? Good outcomes? Poor
												outcomes? Is there anything unusual that happened? Why?
												All of information is used to inform the following piece
												of work. And we can"t seem to get enough of it.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Fixed prices</h4>
											<p>
												Each service is priced uniformly. We can look at
												reductions for longer commitments after you&rsquo;re
												satisfied with our services.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Experience</h4>
											<p>
												We&rsquo;ve evolved alongside the technology we use, and
												with our clients&rsquo; needs. We have deep technical
												knowledge and creative ability, make sound
												recommendations based on real experience.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Accountability</h4>
											<p>
												We are held accountable to you all time because of our
												fixed price and regular monthly reporting.
											</p>
										</li>
									</ol>
								</Fade>
							</div>
						</div>
					</AccordionItem>
				</section>
			</Accordion>
		</>
	);
}

export default About;
