import React from "react";
import { NavLink } from "react-router-dom";

import { Fade } from "react-awesome-reveal";

import { ReactComponent as LogoImage } from "./../assets/images/logo.svg";

const Logo = () => {
  
  return (

    <>

        <div className="site-logo">
          <NavLink to="/" title="Home" className="site-logo__link">

            <Fade direction="down" delay={500} triggerOnce>
              <LogoImage aria-label="Silberstein & Partners" role="img" className="site-logo__image" />
            </Fade>

          </NavLink>
        </div>

    </>

  );
}

export default Logo;
