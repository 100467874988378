import React, { useState } from "react";
import { ChevronUp } from "lucide-react";
  
const ScrollToTopButton = () => {
  
  const [visible, setVisible] = useState(false);
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 480){
      setVisible(true);
    } else if (scrolled <= 480){
      setVisible(false);
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: "smooth"
    });
  };
  
  window.addEventListener("scroll", toggleVisible);
  
  return (
    <button className={`scroll-to-top ${visible ? "visible" : "hidden"}`} onClick={scrollToTop}>
      <ChevronUp className="scroll-to-top__icon" />
    </button>
  );
}
  
export default ScrollToTopButton;
