import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./assets/css/styles.scss";

import ScrollToTopAutomatically from "./components/scrollToTopAutomatically";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Logo from "./components/logo";
import ScrollToTopButton from "./components/scrollToTopButton";

import Home from "./pages/0-home";
import About from "./pages/1-about";
import Services from "./pages/2-services";
import Clients from "./pages/3-clients";
import Contact from "./pages/4-contact";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsConditions from "./pages/terms-and-conditions";
import NotFound from "./pages/not-found";


function App() {

  const [isLoading, setLoading] = useState(true); // TODO: set to `true`

  function wait(time) {
    return new Promise(resolve => setTimeout(() => resolve(), time));
  }

  useEffect(() => {
    let loaderElement = document.querySelector(".loading-screen");

    if(!loaderElement) {
      return;
    }

    wait(2000).then(() => {
      if (loaderElement) {
        loaderElement.classList.add("loading-screen--hidden");
        setLoading(false);
      }
    });
    
    wait(2500).then(() => {
      if (loaderElement) {
        loaderElement.remove();
      }

      let styles = document.getElementById("loader");

      if (styles) {
        styles.remove();
      }
    });
  });

  if (isLoading) {
    return null;
  }

  return (

    <>
      <Router>
        <ScrollToTopAutomatically />
        
        <Header />
        <Logo />
        
        <main className="main">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        <Footer />
      </Router>

      <ScrollToTopButton />
    </>
  );
}

export default App;
