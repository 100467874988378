import React from "react";
import { NavLink } from "react-router-dom";
import { ArrowDownRight } from "lucide-react";
import { Fade } from "react-awesome-reveal";

import Image from "../components/image";
import useDocumentTitle from "../components/setDocumentTitle";
import useDocumentColors from "../components/setDocumentColors";

import HomeImage from "./../assets/images/home-cover.jpg";
import HomeImageWebp from "./../assets/images/home-cover.webp";

const Home = () => {

  useDocumentTitle("Home");
  useDocumentColors("var(--color-white)");

  return (
    <> 
      
      <section className="section section--home-navigation home-navigation">

        <div className="row">
          <div className="column column-lg-2">

          <nav className="main-navigation" role="navigation" aria-label="main-navigation">

            <Fade cascade direction={"left"} damping={0.15} duration={750} triggerOnce>

              <NavLink to="/about" className="main-navigation__link" title="About">
                <span className="main-navigation__number smaller">01</span>
                <span className="main-navigation__title lead">
                  <span className="main-navigation__title-text">
                    About
                  </span>
                  <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                </span>
              </NavLink>

              <NavLink to="/services" className="main-navigation__link" title="Services">
                <span className="main-navigation__number smaller">02</span>
                <span className="main-navigation__title lead">
                  <span className="main-navigation__title-text">
                    Services
                  </span>
                  <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                </span>
              </NavLink>

              <NavLink to="/clients" className="main-navigation__link" title="Clients">
                <span className="main-navigation__number smaller">03</span>
                <span className="main-navigation__title lead">
                  <span className="main-navigation__title-text">
                    Clients
                  </span>
                  <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                </span>
              </NavLink>

              <NavLink to="/contact" className="main-navigation__link" title="Contact">
                <span className="main-navigation__number smaller">04</span>
                <span className="main-navigation__title lead">
                  <span className="main-navigation__title-text">
                    Contact
                  </span>
                  <ArrowDownRight strokeWidth={1} aria-hidden="true" role="img" className="main-navigation__title-icon" />
                </span>
              </NavLink>

            </Fade>
          </nav>

          </div>
        </div>

        <div className="home-navigation__cover">
          <Image jpeg={HomeImage} webp={HomeImageWebp} className="figure--home-navigation-image" title="An abstract red and black wavy background." />
        </div>

      </section>
      
    </>
  );
}

export default Home;
