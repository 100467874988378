import React from "react";

import { Fade } from "react-awesome-reveal";

import useDocumentTitle from "../components/setDocumentTitle";

const TermsConditions = () => {

  useDocumentTitle("Terms and Conditions");

  return (
    <>

      <div className="page-title">
        <div className="row">
          <div className="column column-full">

            <Fade direction="down" delay={1000}>
              <div className="page-title__content spaced">
                <span>Terms and Condition</span>
              </div>
            </Fade>
            
          </div>
        </div>
      </div>

      <section className="section section--simple-document">

        <div className="row">
          <div className="column column-lg-3">
            
            <h1>Terms and Condition</h1>
            <p className="bigger spaced">Last updated: 2023.07.26.</p>

            <p>__</p>

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas delectus sapiente magnam vel quo, nobis rerum quibusdam cupiditate aut numquam atque vero incidunt amet debitis modi dicta laborum dolores architecto!</p>
            <p>Excepturi dolor mollitia magnam iste? Earum laboriosam aperiam possimus. Aut hic libero nihil maiores quidem incidunt ea error delectus magnam inventore, et cum laudantium? Ipsum error totam natus unde necessitatibus.</p>
            <p>Officia consequuntur, quam, provident, aliquam ex modi neque quos labore perferendis numquam unde illo tenetur! Odit beatae vitae quo et molestias eveniet, non quaerat quibusdam iure cupiditate quam itaque voluptas.</p>
            <p>Praesentium labore quae quis nihil quaerat ducimus accusantium aperiam laborum mollitia voluptates repudiandae harum itaque, quia quos, corporis cum qui tempore impedit nesciunt. Nihil optio minus sed cupiditate quos ratione.</p>
            <p>Natus ullam obcaecati ad aspernatur quae labore maxime, itaque, quaerat adipisci minus quibusdam accusamus, odit voluptatem laboriosam perspiciatis veniam asperiores officia accusantium nemo. Facilis cumque ipsa similique provident. Libero, porro.</p>
            <p>Reprehenderit ut atque qui, sint, possimus porro iusto aspernatur doloribus eius excepturi inventore, vel eligendi obcaecati soluta explicabo officia facere totam magnam facilis aut corporis repellat? Iste molestias vel libero?</p>
            <p>Placeat corporis consequatur iste consequuntur deserunt qui. Necessitatibus excepturi tempore veniam inventore qui soluta beatae amet corrupti, temporibus quis pariatur error nulla expedita, quam earum molestias nobis neque aut cum.</p>
            <p>Ullam, nulla odio voluptate laborum doloribus itaque facere fuga, soluta error eveniet ipsa nemo, cum sed assumenda officiis quod dicta sequi totam. Deserunt id reiciendis commodi fugit animi consectetur similique.</p>
            <p>Reprehenderit id ratione alias unde sequi libero iusto fuga debitis iure eum laboriosam at nam quasi consequuntur placeat ea, veritatis tempora. Eos magnam iure at. Incidunt qui tempora repellendus. Eaque?</p>
            <p>Eveniet reprehenderit pariatur quo nobis fuga. Perferendis similique accusantium vel incidunt reiciendis voluptas a veniam eaque facere, molestias omnis pariatur est impedit, consequatur voluptatibus? Nostrum asperiores quae non eligendi aut?</p>
            <p>Odio obcaecati nihil at. Voluptates sunt rerum hic nemo quidem non maxime numquam deleniti, deserunt repellendus quos similique. Odit numquam dicta ipsam accusantium, temporibus in tempore veniam suscipit eveniet quos.</p>
            <p>Quo, voluptates nulla. Eligendi, nesciunt beatae veniam vel atque laudantium ut iusto minima dolore repellendus tempora, reprehenderit neque ex aliquam fugit nam. Nisi quidem repudiandae earum, sit consectetur deleniti dolor.</p>
            <p>Ab doloremque laboriosam quidem natus unde atque illum voluptatibus eaque quis ut velit incidunt iure sit, in explicabo nesciunt mollitia necessitatibus molestiae maiores fugit veniam maxime! Rerum blanditiis aliquid quis?</p>
            <p>Quibusdam ipsum nihil, corporis reiciendis delectus recusandae aut quae dolore unde, maxime tenetur, quas quasi dolor saepe temporibus. Commodi cum possimus inventore voluptate quasi ducimus voluptatem deleniti velit maxime aut.</p>
            <p>Dignissimos fugiat sint eos alias in maxime, voluptatibus eveniet? Perferendis ipsum molestiae fuga distinctio quia facere harum soluta ab in similique. Repellendus ut atque suscipit quam reiciendis accusantium illo incidunt.</p>
            <p>Ea, animi possimus debitis cupiditate deserunt cum earum aspernatur odio? Aperiam excepturi, unde cupiditate saepe maxime aliquid sed omnis dolore cumque labore nobis est modi ab natus non doloribus aliquam.</p>

          </div>
        </div>

      </section>

    </>
  );
}

export default TermsConditions;
