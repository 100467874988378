import { AccordionItem as Item } from "@szhsin/react-accordion";
import { ArrowDown } from "lucide-react";


const AccordionItem = ({ header, ...rest }) => (

  <Item
    {...rest}
    headingTag="h2"
    header={
      <>
        <span className="szh-accordion__item-heading-text">{header}</span>
        <ArrowDown strokeWidth={1} aria-hidden="true" role="img" className="szh-accordion__item-arrow" />
      </>
    }
  />
  
);

export default AccordionItem;
