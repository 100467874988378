import React from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { ReactTyped } from "react-typed";

import Image from "../components/image";
import ClientLogoGallery from "../components/clientLogoGallery";
import useDocumentTitle from "../components/setDocumentTitle";
import useDocumentColors from "../components/setDocumentColors";

import HeroImage from "./../assets/images/clients-hero.jpg";
import HeroImageWebp from "./../assets/images/clients-hero.jpg";
import Img01 from "./../assets/images/clients-01.jpg";
import Img01Webp from "./../assets/images/clients-01.webp";

const Clients = () => {

  useDocumentTitle("Clients");
  useDocumentColors("var(--color-whiteheron)");

  return (
		<>
			<div className="page-title">
				<div className="row">
					<div className="column column-full">
						<Fade direction="down" delay={1000} triggerOnce>
							<div className="page-title__content spaced">
								<span>03</span>
								<span>Clients</span>
							</div>
						</Fade>
					</div>
				</div>
			</div>

			<section className="section section--hero">
				<div className="row">
					<div className="column column-lg-4 column-xl-3">
						<h1>
							<ReactTyped
								strings={["We know what works."]}
								typeSpeed={40}
								startDelay={500}
							/>
						</h1>
					</div>
				</div>
				<div className="row">
					<div className="column column-lg-2">
						<Fade direction="up" delay={1000} triggerOnce>
							<p className="bigger">
								Silberstein and Partners works with select corporate, nonprofit
								and startup companies, on both long and short-term
								collaborations.
							</p>
						</Fade>
					</div>
				</div>
			</section>

			<section className="section section--hero-image">
				<Image
					jpeg={HeroImage}
					webp={HeroImageWebp}
					className="figure--clients-hero"
					title="Businessman dressed in white shirt sitting in cafe and using laptop"
				/>
			</section>

			<section className="section section--client-logos">
				<div className="row">
					<ClientLogoGallery />
				</div>
			</section>

			<section
				className="section section--clients-content"
				style={{ position: "relative" }}
			>
				<div className="row">
					<div className="column column-lg-2">
						<p className="bigger">
							There&rsquo;s a reason we have such a dedicated customer base. We
							take pride in our methods, as well as our service level and
							quality. We always approach campaign management with a hands-on,
							client-first manner.
						</p>

						<Image
							jpeg={Img01}
							webp={Img01Webp}
							className="figure--clients-01"
							title="A woman sitting at a desk with two monitors in front of her."
						/>

						<Fade cascade triggerOnce>
							<p className="lead">Might we feature your organization here?</p>
							<p className="bigger">
								It could happen. Let&rsquo;s find out if we&rsquo;re a good fit.
							</p>
							<p>
								<NavLink to="/contact" className="button" title="Contact us">
									<span className="button__text">Contact us</span>
								</NavLink>
							</p>
						</Fade>
					</div>
				</div>
			</section>
		</>
	);
}

export default Clients;
