import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const CountUpAnimation = ({ start, end, duration, decimalPlaces }) => {

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  return (
    <span ref={ref} className="count-up-container">
      {inView ? (
        <CountUp start={start} end={end} duration={duration} decimalSeparator="." decimalPlaces={decimalPlaces} />
      ) : (
        <span>{start}</span>
      )}  
    </span>
  );
};

export default CountUpAnimation;
