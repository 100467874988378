import React from "react";

import { Fade } from "react-awesome-reveal";

const ClientLogoGallery = () => {
  
  const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  const importAll = (requireContext) => requireContext.keys().map(requireContext);
  const images = importAll(require.context('./../assets/images/partners', false, /\.(svg)$/));

  return (

    <>

        {images.map((image, index) => {
          const imageName = titleCase(image.split('/').pop().split('.')[0].replaceAll("-", " "));

          return (

            <Fade key={index} direction="up" damping={0.5} duration={1000} cascade triggerOnce>

              <figure className="figure figure--client-logo">
                <img
                  src={image}
                  alt={`Partner logo, ${imageName}`}
                  title={`Partner logo, ${imageName}`}
                  className="figure__src"
                  draggable="false"
                  loading="lazy"
                />
              </figure>

            </Fade>

          );
        
        })}

    </>
  
  );
}

export default ClientLogoGallery;
