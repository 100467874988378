import React from "react";

import useDocumentColors from "../components/setDocumentColors";
import useDocumentTitle from "../components/setDocumentTitle";
import AccordionItem from "../components/accordionItem";
import Image from "../components/image";
import CountUpAnimation from "../components/countUpAnimation";

import { Fade } from "react-awesome-reveal";
import { Accordion } from "@szhsin/react-accordion";
import { ReactTyped } from "react-typed";

import Img01 from "./../assets/images/services-01-ppc.jpg";
import Img01Webp from "./../assets/images/services-01-ppc.webp";
import Img02 from "./../assets/images/services-02-digital-pr.jpg";
import Img02Webp from "./../assets/images/services-02-digital-pr.webp";
import Img03 from "./../assets/images/services-03-digital-pr.jpg";
import Img03Webp from "./../assets/images/services-03-digital-pr.webp";

const Services = () => {

  useDocumentTitle("Services");
  useDocumentColors("var(--color-memorylane)");
  
  return (
		<>
			<div className="page-title">
				<div className="row">
					<div className="column column-full">
						<Fade direction="down" delay={1000} triggerOnce>
							<div className="page-title__content spaced">
								<span>02</span>
								<span>Services</span>
							</div>
						</Fade>
					</div>
				</div>
			</div>

			<section className="section section--hero">
				<div className="row">
					<div className="column column-lg-4 column-xl-3">
						<h1>
							<ReactTyped
								strings={["Brain. Heart. Math."]}
								typeSpeed={40}
								startDelay={500}
							/>
						</h1>
					</div>
				</div>
				<div className="row">
					<div className="column column-lg-2">
						<Fade direction="up" delay={1000} cascade triggerOnce>
							<p className="bigger">
								Fancy is worthless, campaigns must convert. Full stop.
								We&rsquo;re addicted to analytics, metrics, and data, not
								mentioning pivots based on actionable intelligence.
							</p>
							<p className="bigger">
								We assess. Revise. Repeat. Never stop. Ever.
							</p>
						</Fade>
					</div>
				</div>
			</section>

			<Accordion
				transition
				transitionTimeout={250}
				onStateChange={({ key: item, current: { status } }) => {
					if (status === "entered") {
						//item.scrollIntoView({ behavior: "smooth", block: "start" });
						//item.scrollIntoView();

						/*
            window.scrollTo({
              top: item.offsetTop - 96,
              behavior: "smooth"
            });
            */

						console.log(item);

						/*
            let heroElem = document.querySelector(".section.section--hero");
            let scrollPosition = heroElem.offsetTop + heroElem.offsetHeight + 96;

            window.scrollTo({
              top: item.querySelector('.szh-accordion__item-panel').offsetTop + 58,
              behavior: "smooth"
            });
            */

						item.scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
						});
					}
				}}
			>
				<section className="section section--services-ppc">
					<AccordionItem header="PPC">
						<div className="row">
							<div className="column column-lg-3">
								<Fade direction="up" triggerOnce>
									<p className="lead" style={{ marginBottom: "3rem" }}>
										Where&rsquo;s the best place to bury a body? Page 2 of
										Google. Ensure your brand is the first thing people see when
										they&rsquo;re looking for your services.
									</p>
								</Fade>
							</div>
						</div>

						<div className="row" style={{ rowGap: "3rem" }}>
							<Fade className="column column-md-1" cascade triggerOnce>
								<div>
									<p className="lead" style={{ marginBottom: "0.25em" }}>
										<CountUpAnimation
											start={0}
											end={85}
											duration={3}
											isCounting
										/>
										%
									</p>
									<p className="bigger m-0">
										of consumers use the internet to search for local
										businesses.
									</p>
								</div>

								<div>
									<p className="lead" style={{ marginBottom: "0.25em" }}>
										<CountUpAnimation
											start={0}
											end={50}
											duration={3.5}
											isCounting
										/>
										%
									</p>
									<p className="bigger m-0">
										of consumers use the internet to search for local
										businesses.
									</p>
								</div>

								<div>
									<p className="lead" style={{ marginBottom: "0.25em" }}>
										<CountUpAnimation
											start={0.0}
											end={64.6}
											duration={4}
											isCounting
										/>
										%
									</p>
									<p className="bigger m-0">
										of people click on Google ad while making an online
										purchase.
									</p>
								</div>

								<div>
									<p className="lead" style={{ marginBottom: "0.25em" }}>
										<CountUpAnimation
											start={0}
											end={200}
											duration={4.5}
											isCounting
										/>
										%
									</p>
									<p className="bigger m-0">
										ROI is generated on paid ads i.e. $2 earned for every $1
										spent.
									</p>
								</div>
							</Fade>
						</div>

						<div className="row">
							<div className="column column-lg-3">
								<Fade direction="up" triggerOnce>
									<p className="h2" style={{ margin: "3rem 0" }}>
										No marketing fluff, no vanity metrics. We focus on real
										results.
									</p>
								</Fade>
							</div>
						</div>

						<div className="row" style={{ alignItems: "center" }}>
							<div className="column column-lg-2">
								<Fade cascade triggerOnce>
									<p>
										At Silberstein & Partners, we live and breathe digital, and
										we can&rsquo;t wait to develop your detailed ad campaigns
										across Google, Facebook, Instagram, and LinkedIn. We
										specialise in creating bespoke, highly targeted, user
										focused PPC campaigns, focusing on the metrics that matter
										to your business, while never taking our eyes off the wider
										picture.
									</p>
									<p>
										We constantly strive to maximise revenue, while putting
										context at the heart of everything we do. Our team focuses
										on developing PPC best practice through well-targeted
										campaign structures, stringent keyword optimisation, ad copy
										testing, audience insight analysis and KPI monitoring. We
										constantly update our accounts with new features to drive
										stronger performance for our clients.
									</p>
								</Fade>
							</div>

							<div className="column column-lg-2">
								<Image
									jpeg={Img01}
									webp={Img01Webp}
									className="figure--services-01"
									title="Programmer working on computer in IT office"
								/>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Fade direction="left" cascade triggerOnce>
									<ul className="styled-list styled-list--border">
										<li className="styled-list__item bigger">
											Increasing post and page engagement
										</li>
										<li className="styled-list__item bigger">
											Increasing website traffic and generating leads
										</li>
										<li className="styled-list__item bigger">
											Brand recognition and consideration
										</li>
										<li className="styled-list__item bigger">
											Increasing the number of video views
										</li>
										<li className="styled-list__item bigger">
											Conversions and sales
										</li>
									</ul>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-3">
								<Fade triggerOnce>
									<p>
										We&rsquo;re convinced you&rsquo;ll enjoy working with us,
										and you&rsquo;ll see why our client retention is so high.
										You never look back once you&rsquo;ve decided on Silberstein
										and Partners. If you&rsquo;ve had trouble with PPC campaigns
										in the past, we&rsquo;d appreciate the opportunity to show
										you how different things can be when you deal with the
										proper agency.
									</p>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Fade direction="left" cascade triggerOnce>
									<ul className="styled-list styled-list--inline">
										<li className="styled-list__item spaced bigger">
											Creatively driven
										</li>
										<li className="styled-list__item spaced bigger">
											Process orientated
										</li>
										<li className="styled-list__item spaced bigger">
											Results focused
										</li>
										<li className="styled-list__item spaced bigger">
											Oriented by process
										</li>
										<li className="styled-list__item spaced bigger">
											Fueled by outcomes
										</li>
										<li className="styled-list__item spaced bigger">
											Grounded in honesty
										</li>
									</ul>
								</Fade>
							</div>
						</div>
					</AccordionItem>
				</section>

				<section className="section section--services-social-media">
					<AccordionItem header="Social media">
						<div className="row">
							<div className="column column-lg-3">
								<Fade triggerOnce>
									<p className="bigger">
										To step up your game, you must have a distinct, compelling,
										and robust online presence. This is where Silberstein and
										Partners becomes your best ally. But first, you must
										remember that social media is not easy, nor cheap. Consumer
										preferences change, platforms come and go, while others
										evolve, algorithms update, policies change, and new features
										emerge. But one thing remains constant – social media
										continues to give opportunities to our clients to
									</p>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Fade direction="left" cascade triggerOnce>
									<ul className="styled-list styled-list--border">
										<li className="styled-list__item bigger">
											Engage audience
										</li>
										<li className="styled-list__item bigger">Validate brand</li>
										<li className="styled-list__item bigger">
											Drive to purchase
										</li>
										<li className="styled-list__item bigger">
											Sell products/services directly
										</li>
									</ul>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-3">
								<Fade direction="up" triggerOnce>
									<h3>
										Social marketing eliminates the middlemen, providing brands
										the unique opportunity to have a direct relationship with
										their customers. It is not a media. Rather a key is to
										listen, engage, and build relationships.
									</h3>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Fade cascade triggerOnce>
									<ol className="styled-list styled-list--numbered styled-list--two-col">
										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Social media strategy
											</h4>
											<p className="styled-list__description">
												Social media is about more than just posting content, it
												become an integrated place where you can contact your
												target audience. We help you nurture and engage your
												audience; help you create social strategy that would
												generate brand awareness. Our team is dedicated to
												creating bulletproof strategy for you, covering
												strategy, content creation, audience nurturing,
												influencer relations, paid promotion & so much more!
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Social media marketing
											</h4>
											<p className="styled-list__description">
												By now, most companies understood that the reach brands
												can receive from organic content is super tiny. The
												average Facebook post might reach 5% of your
												followers–if you&rsquo;re lucky. Large-scale reach has
												largely become a pay-to-play proposition. However, the
												content on your profiles is still critical to your
												customers conducting their due diligence about your
												products and brand.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Content marketing</h4>
											<p className="styled-list__description">
												We are living in a content-driven world, and we want to
												help your site burst with exciting digital content.
												Content marketing through blogs & whitepapers is a great
												way to reach new customers organically, and to establish
												your brand as a thought leader in your industry.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Paid social</h4>
											<p className="styled-list__description">
												We love creating cool work that meets your business
												needs, but we love it even more when that work reaches
												more of the right people. That&rsquo;s where our paid
												media team comes in. They know who to target, where and
												when to target them and making sure they see a relevant
												piece of communication. In practical terms, we create,
												test & optimize high-ROI paid social campaigns across
												Facebook, Instagram, LinkedIn, TikTok & more.
											</p>
										</li>
									</ol>
								</Fade>
							</div>
						</div>
					</AccordionItem>
				</section>

				<section className="section section--services-digital-pr">
					<AccordionItem header="Digital PR">
						<div className="row">
							<div className="column column-lg-4">
								<Image
									jpeg={Img02}
									webp={Img02Webp}
									className="figure--services-02"
									title="Graphic designer woman using digital graphic tablet while working at modern office"
								/>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-3">
								<Fade direction="up" triggerOnce>
									<p className="lead" style={{ margin: "3rem 0" }}>
										Utilize our enormous media network to shape the narrative
										surrounding your brand. Don&rsquo;t let third parties lead
										the conversation about your company.
									</p>
								</Fade>

								<Fade cascade triggerOnce>
									<p>
										Whether you realize it or not, there are ongoing debates
										about your brand both online and offline. Using Silberstein
										and Partners for strategic PR services is the most efficient
										way for taking back control of the narrative. Digital PR
										(Public Relations) is the technique of building
										relationships with journalists, bloggers, and other online
										influencers through online channels and platforms to acquire
										media coverage and improve a brand&rsquo;s online
										reputation.
									</p>
									<p>
										Digital public relations (PR) use a variety of strategies to
										promote a business or product via online channels, including
										content marketing, influencer outreach, social media
										marketing, and search engine optimization (SEO). The goal is
										to establish a positive online reputation for the business,
										generate excitement about new products or services, and
										boost exposure and credibility through online media
										coverage.
									</p>
								</Fade>

								<Fade direction="up" triggerOnce>
									<h3>Here are some key elements of digital PR</h3>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-2">
								<Fade cascade triggerOnce>
									<ol
										className="styled-list styled-list--numbered"
										style={{ marginTop: "0" }}
									>
										<li className="styled-list__item">
											<h4 className="styled-list__title">Media relations</h4>
											<p>
												Building relationships with journalists, editors, and
												other media professionals who can give media coverage
												and visibility for the company is a key component of
												digital public relations (PR). Pitching article
												concepts, press releases, and other content that
												highlights the company&rsquo;s news or area of expertise
												can be a part of this.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Content development and promotion
											</h4>
											<p>
												Creating excellent, educational, and interesting content
												is crucial for social media. The brand&rsquo;s expertise
												and values can be demonstrated through blog entries,
												films, infographics, and other sorts of content.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">
												Reaching out to influencers
											</h4>
											<p>
												As part of digital PR strategies, we establish
												connections with influencers who may assist a
												brand&rsquo;s products or services get more attention.
												These could include bloggers, podcasters, social media
												influencers, and other online personalities with a
												sizable following within the brand&rsquo;s intended
												audience.
											</p>
										</li>
									</ol>
								</Fade>
							</div>

							<div className="column column-lg-2">
								<Image
									jpeg={Img03}
									webp={Img03Webp}
									className="figure--services-03"
									title="A man sitting at a desk looking at a computer."
								/>
							</div>
						</div>
					</AccordionItem>
				</section>

				<section className="section section--services-webshop-marketing">
					<AccordionItem header="Webshop marketing">
						<div className="row">
							<div className="column column-lg-3">
								<Fade triggerOnce>
									<p className="bigger">
										We make the difficult simple and drive growth. We
										don&rsquo;t stop until we completely understand your
										visitors&rsquo; whole experience, from the first click to
										returning purchases. We assist you in optimizing,
										developing, and improving all important components and
										touchpoints of your sales funnel to make the most of every
										single visit on your site. We provide a variety of online
										conversion services, including traffic generation, lead
										generation, and overall performance analysis and
										optimization. We specialize in integrated performance-driven
										solutions that boost and rigorously fine-tune your ecommerce
										operations.
									</p>
								</Fade>

								<Fade direction="up" triggerOnce>
									<h3 style={{ marginTop: "3rem" }}>How it works</h3>
								</Fade>
							</div>
						</div>

						<div className="row">
							<div className="column column-lg-4">
								<Fade cascade triggerOnce>
									<ol
										className="styled-list styled-list--numbered styled-list--three-col"
										style={{ marginTop: "0" }}
									>
										<li className="styled-list__item">
											<h4 className="styled-list__title">Strategy</h4>
											<p>
												We&rsquo;ve got our thinking caps on. During the
												strategy phase, we dig deep into your business &
												industry to identify and investigate your marketing
												needs. Every business is unique, and your strategy
												should reflect that.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Implementation</h4>
											<p>
												Time to practice what we preach. In the implementation
												phase, we unleash our inner agency and put your strategy
												into action: setting up, monitoring & actively
												optimizing all chosen channels to ensure optimal ROI.
											</p>
										</li>

										<li className="styled-list__item">
											<h4 className="styled-list__title">Growth</h4>
											<p>
												Now for the fun part, growth! If you want to see real
												gains, you&rsquo;re on the right marketing
												agency&rsquo;s site. Watch the work speak for itself as
												you see tangible results from our actions, and your
												business reaps the benefits.
											</p>
										</li>
									</ol>
								</Fade>
							</div>
						</div>
					</AccordionItem>
				</section>
			</Accordion>
		</>
	);
}

export default Services;
