import { useEffect, useState } from "react";

const useDocumentColors = (background, text = "var(--color-black)") => {

  const [documentColors, setDocumentColors] = useState({
    backgroundColor: background,
    textColor: text
  });

  useEffect(() => {
    document.body.style.cssText = `
      --main-background: ${documentColors.backgroundColor};
      --main-text: ${documentColors.textColor}
    `;

    return () => {
      document.body.style.cssText = "";
    }
  }, [documentColors.backgroundColor, documentColors.textColor]);

  return [documentColors, setDocumentColors];
}

export default useDocumentColors;
